import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'full_icon';
const namespace = 'ui-pdp-icon ui-pdp-icon--full';

const IconFull = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    width="41"
    height="13"
    viewBox="0 0 41 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconFull.propTypes = {
  className: string,
};

IconFull.defaultProps = {
  className: null,
};

IconFull.ICON_ID = ICON_ID;

export default React.memo(IconFull);
export { IconFull };
